<template>
  <div class="header-wrapper">
    <ul class="nav navbar-nav" v-if="menuHeader.length > 0">
      <li v-bind:class="[item.title == menuTypeOffer ? 'has-mega-menu' : '',isOpen != key ? '' : 'open']"  @click="openMenu(key)" v-for="(item ,key) in menuMain" :key="key">
        <a :class="[window.width > 991 ? 'pl-2 pr-2' : 'text-black pl-2 pr-2']" :href=item.url v-if="item.url != '#'">{{item.title}}</a>
        <a :class="[window.width > 991 ? 'pl-2 pr-2' : 'text-black pl-2 pr-2']" href="javascript:;" v-if="item.url == '#'">{{item.title}}<i class="fa fa-chevron-down"></i></a>
        <ul class="sub-menu" v-if="item.url == '#' && item.title != menuTypeOffer">
          <li v-for="(itemSub ,key) in subMain(item.ID)" :key="key" class="">
            <a class="text-black" :href=itemSub.url v-if ="itemSub.object != 'custom'">{{itemSub.title}}</a>
            <a class="text-black" :href=itemSub.url v-if ="itemSub.object == 'custom'" target="_blank">{{itemSub.title}}</a>
          </li>
        </ul>
        <ul class="mega-menu text-black" v-if="item.url == '#' && item.title == menuTypeOffer">
          <li v-for="(itemSub ,key) in subMain(item.ID)" :key="key" class="">
            <a class="text-black" :href=itemSub.url>{{itemSub.title}}</a>
            <ul>
              <li v-for="(itemSub2 ,key) in subMain2(itemSub.ID)" :key="key" class="">
                <a class="text-black" :href=itemSub2.url v-if ="itemSub2.object != 'custom'">{{itemSub2.title}}</a>
                <a class="text-black" :href=itemSub2.url v-if ="itemSub2.object == 'custom'" target="_blank">{{itemSub2.title}}</a>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <div class="menu-preloader" v-else>
        <i class="fa fa-cog text-white fa-spin"></i>
    </div>
  </div>
</template>

<style scoped>
.text-white{
  color:white!important;
}
.text-black{
  color:black!important;
}
.text-black:hover {
  color:black!important;
}
.header-wrapper {
    min-width: 100px;
    margin: 0 auto;
    width:100%;
}
.menu-preloader {
    position:absolute;
    left:50%;
}

</style>

<script>
export default {
  data() {
    return {
      menuHeader: [],
      isOpen: null,
      menuTypeOffer: this.$menuTypeOffer,
      textColor:'',
      window: {
        width: 0,
        height: 0
      },
    }
  },
  created() {
    let uri = this.$apiUrl + this.$menu;
    this.axios.get(uri).then(response => {
      this.menuHeader = response.data;
    });
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    this.menuHeader = null;
    delete this.menuHeader;
    location.reload();
  },
  computed: {
    menuMain:function() {
      const menuMain = this.menuHeader.filter(menuHeader => menuHeader.menu_item_parent == 0);
      return menuMain
    }
  },
  methods: {
    subMain(id){
      const subMain = this.menuHeader.filter(menuHeader => menuHeader.menu_item_parent == id);
      return subMain
    },
    subMain2(id){
      const subMain2 = this.menuHeader.filter(menuHeader => menuHeader.menu_item_parent == id);
      return subMain2
    },
    openMenu(key){
        if(this.isOpen == key){
          this.isOpen = null
        }else{
          this.isOpen = key; 
        } 
    },
    handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    }
  }
};
</script>
